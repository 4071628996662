<template>
<!--
  <div
    class="doodle-container-absolute"
  >

-->
    <div class="doodle" v-html="doodle" />
<!--
    <LocalizedContent ref="lc" />

  </div>
-->
</template>
<script>

import {emit, inject, onMounted, ref, toRef, watch} from "vue";
import {translationStore} from "@/stores/translation";
import Axios from "axios";

export default {
    "name": "Doodle",
    "components": {
    },
    "props": {
        "data": {
            "type": String,
            "default": ""
        },
        "doodleKey": {
            "type": Number,
            "default": 0
        }
    },
    "emits": ["setDoodle"],
      setup (props, {emit}) {

        const translation = translationStore(),
            userDoodle = toRef(props, 'data'),
            doodle = ref(),
            doodleKey = toRef(props, 'doodleKey'),
            setDoodle = () => {

                emit(
                    "set-doodle",
                    false
                );

            };
        onMounted(() => {

            watch(
                doodleKey,
                () => {

                      let d = userDoodle.value;

                      doodle.value = `<css-doodle key="${doodleKey.value}" click-to-update>
                          ${d}
                        </css-doodle>`;

                }
            );

        });
        return {"localize": translation.localize, doodle, userDoodle, doodleKey}

    }
};

</script>
<style scoped>
  css-doodle {
    width: 100%;
    height: 100%;
  }
  .doodle {
    width: 100%;
    height: 100%;
  }
  .doodle-container-fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .doodle-container-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
  }
</style>
