<template>
  <div
    class="relative"
    ref="thisComponent"
  >

    <div
      class="component-controls"
      v-if="owner === true"
    >

      <slot name="edit" />

      <div class="flex-row margin-ten">
        <div
          class="normal-link"
          @click="openHTMLModal = true"
        >
          {{ localize('TEXT_EDIT') }} HTML
        </div>
        <div
          class="normal-link"
          @click="openQuillModal = true"
        >
          {{ localize('TEXT_EDIT') }}
        </div>
        <form class="set-image">
          <div class="normal-link">
          {{ localize('TEXT_IMAGE') }}
          </div>
          <label
            class="component-file-upload"
            :for="componentFileUploadId"
          />
          <input
            :id="componentFileUploadId"
            type="file"
            accept="image/*,video/*"
            @change="emitSetImage"
          >
        </form>
        <div
          class="normal-link"
          @click="setOrientation"
        >
          {{ localize('TEXT_ORIENTATION') }}
        </div>
        <div
          class="normal-link"
          @click="emitRemoveComponent"
        >
          {{ localize('TEXT_REMOVE') }}
        </div>
      </div>
    </div>

<!--
    <div class="component-container" v-motion-slide-visible-once-right>
-->
    <div :style="'background-color: ' + data.backgroundColor + ';'
          + 'background: ' + data.background + ';'
          + 'height: ' + data.height + ';'
          + 'border: ' + data.border + ';'
          + 'border-radius: ' + data.borderRadius + ';'
          + '-webkit-clip-path: ' + data.angularBorderStyle + ';'
          + 'clip-path: ' + data.angularBorderStyle + ';'
          + 'margin: ' + data.margin + ';'
          + 'padding: ' + data.padding + ';'
          + 'box-shadow: ' + data.boxShadow + ';'
          + 'color: ' + data.color + ';'
          + 'position: relative;'
          + 'display: flex;'
          + 'flex: 1;'
          + 'flex-direction: column;'
          + 'align-items: center;'
          + 'justify-content: center;'
          + 'width: 100%;'
          + 'overflow: hidden;'"
      >

      <div
        v-if="data.backgroundImage"
      >
        <img
          :src="data.backgroundImage"
          class="component-background-image"
        />
      </div>
      <video
        v-if="data.backgroundVideo"
        class="component-background-video"
        :poster=data.backgroundPoster
        :key="data.backgroundVideo"
        loop
        autoplay
        muted
      >
        <source
          :src="data.backgroundVideo"
          type="video/mp4"
        >
      </video>

      <div
        :class="responsiveOrientation"
        style="width: 100%;"
      >
        <div
          v-if="data.class === 'launcher'"
        >
          <Launcher
            v-if="data.nft_address"
            :primary_color="primaryColor"
            :nft_address="data.nft_address"
            class="launcher"
          />
        </div>
        <div
          v-else-if="data.class === 'minter'"
          style="width: 100%;"
        >
          <LazyMint
            class="minter"
            style="inherit"
            v-if="data.nft_address"
            :set_background="false"
            :nft_address="data.nft_address"
          />
        </div>
        <div
          class="component-text"
          v-else-if="data.content"
        >
          <div v-html="data.content" />
        </div>
        <div
          class="inherit"
          v-if="data.video||data.image"
        >
          <video
            v-if="data.video"
            class="component-video"
            :poster=data.poster
            loop
            autoplay
            muted
          >
            <source
              :src="data.video"
              type="video/mp4"
            >
          </video>
          <img
            v-if="data.image"
            class="component-image" :src="data.image" />
        </div>
      </div>

    </div>

    <div
      v-motion
      initial="{
        scale: 1,
      }"
      enter="{
        scale: 2,
        transition: {
          delay: 1000,
        },
      }"
    />

    <CModal
      v-if="openHTMLModal"
      color="#995dd4"
      width="large"
      @close="openHTMLModal = false"
    >
      <template #header>
        <div> HTML </div>
      </template>
      <template #body>
        <CTextArea
          class="margin-top"
          :value="data.content"
          :title="localize('DATA_CONTENT')"
          :placeholder="localize('TEXT_CONTENT_PLACEHOLDER')"
          @inputValue="data.content = $event"
        />
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="openQuillModal"
      color="#995dd4"
      width="large"
      @close="openQuillModal = false"
    >
      <template #header>
        <div> {{ localize('TEXT_TEXT') }} </div>
      </template>
      <template #body>
        <QuillEditor
          v-model:content="data.content"
          class="quill-input"
          content-type="html"
          :modules="modules"
          toolbar="full"
          theme="snow"
          enable="true"
        />
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

    <CModal
      v-if="addSegmentModal"
      color="#995dd4"
      width="large"
      @close="addSegmentModal = false"
    >
      <template #header>
        <div> Add Segment </div>
      </template>
      <template #body>

        <div
          class="flex-row component-border"
          @click="setComponent('text_and_image')"
        >
          <div>
            [ Text ]
          </div>
          <div>
            [ Image ]
          </div>
        </div>

        <div
          class="flex-col component-border"
          @click="setComponent('text_and_image')"
        >
          <div>
            [ Text Only ]
          </div>
        </div>

        <div
          class="flex-col component-border"
          @click="setComponent('image_only')"
        >
          <div>
            [ Image Only ]
          </div>
        </div>

      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>

  </div>
</template>
<script>

import {emit, inject, onMounted, reactive, ref, toRefs, unref, watch} from "vue";
import {translationStore} from "@/stores/translation";
import {useRoute} from "vue-router";
import {QuillEditor} from "@vueup/vue-quill";
import BlotFormatter from "quill-blot-formatter";
import htmlEditButton from "quill-html-edit-button";
import ImageUploader from "quill-image-uploader";
import Axios from "axios";
import CreatorPost from "./CreatorPost.vue";
import Loading from "./Loading.vue";
import LazyMint from "./LazyMint.vue";
import Launcher from "./Launcher.vue";


export default {
    "name": "ProjectComponent",
    "components": {
        CreatorPost,
        Loading,
        QuillEditor,
        LazyMint,
        Launcher
    },
    "props": {
        "data": {
            "type": "Object",
            "default": ""
        },
        "orientation": {
            "type": String,
            "default": "cmp-row"
        },
        "owner": {
            "type": Boolean,
            "default": false
        },
        "segmentId": {
            "type": Number
        },
        "componentId": {
            "type": Number
        },
        "componentStyle": {
            "type": String
        }
    },
    "emits": ["close","setComponentImage","setOrientation","removeComponent"],
    setup (props, {emit}) {

        const translation = translationStore(),
            route = useRoute(),
            userInfo = inject("userInfo"),
            loading = ref(false),
            link = ref(""),
            thisComponent = ref(),
            componentFileUploadId = ref(),
            data = ref(props.data),
            isOwner = ref(props.owner),
            orientation = ref(props.orientation),
            segmentId = ref(props.segmentId),
            componentId = ref(props.componentId),
            componentStyle = ref(props.componentStyle),
            addSegmentModal = ref(false),
            openQuillModal = ref(false),
            openHTMLModal = ref(false),
            responsiveOrientation = ref(""),
            inPost = ref(false),
            serverConfig = inject("serverConfig"),
            modules = [
                {

                    "name": "blotFormatter",
                    "module": BlotFormatter,
                    "options": {/* options */}

                },
                {
                    "name": "htmlEditButton",
                    "module": htmlEditButton,
                    "options": {}
                },
                {

                    "name": "imageUploader",
                    "module": ImageUploader,
                    "options": {
                        "upload": (file) => {

                            return new Promise((resolve, reject) => {

                                const formData = new FormData();
                                formData.append(
                                    "file",
                                    file
                                );

                                Axios.post(
                                    "/uploadImage",
                                    formData
                                ).
                                    then((res) => {
                                        console.log(res);
                                        resolve(res.data.url);

                                    }).
                                    catch((err) => {

                                        reject("Upload failed");
                                        console.error(
                                            "Error:",
                                            err
                                        );

                                    });

                            });

                        }

                    }

                }
            ],
            setComponent = function (type) {

                component.type = type

            },
            emitSetImage = function (e) {

                emit(
                    "setComponentImage",
                    {e,segmentId,componentId}
                );

            },
            emitHideModal = function () {

                emit(
                    "close",
                    false
                );

            },
            emitRemoveComponent = function (e) {

                emit(
                    "removeComponent",
                    {e,segmentId,componentId}
                );

            },
            setOrientation = function () {

                if (orientation.value === "cmp-row") {

                    orientation.value = "cmp-column";

                } else if (orientation.value === "cmp-column") {

                    orientation.value = "cmp-row-reverse";

                } else if (orientation.value === "cmp-row-reverse") {

                    orientation.value = "cmp-column-reverse";

               } else {

                    orientation.value = "cmp-row";

                }

                emit(
                    "setOrientation",
                    {segmentId,componentId,orientation}
                );

                updateView();

            },
            updateView = function () {

                if (thisComponent.value) {

                    const cw = thisComponent.value.clientWidth;

                    if (cw > 1024) {

                        responsiveOrientation.value = orientation.value;

                    } else {

                        if (orientation.value === "cmp-row") {

                            responsiveOrientation.value = "cmp-row-wrap"

                        } else if (orientation.value === "cmp-row-reverse") {

                            responsiveOrientation.value = "cmp-row-reverse-wrap"

                        } else {

                            responsiveOrientation.value = orientation.value;

                        }

                    }

                }

            };

        onMounted(() => {

            setOrientation();

            componentFileUploadId.value = "component-file-upload-" + segmentId.value + "-" + componentId.value;

            setTimeout(
                () => {

                    updateView();

                },
                1000
            );

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            userInfo,
            thisComponent,
            emitHideModal,
            emitRemoveComponent,
            addSegmentModal,
            orientation,
            responsiveOrientation,
            componentId,
            segmentId,
            componentStyle,
            openQuillModal,
            openHTMLModal,
            emitSetImage,
            setOrientation,
            loading,
            data,
            isOwner,
            modules,
            componentFileUploadId,
            serverConfig};

    }
};

</script>
<style scoped>
.save-create {
  display: flex;
  margin-top: 20px;
  width: 100px;
  justify-content: center;
  font-size: 0.9em;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4);
}
.link-container {
  display: flex;
  justify-content: start;
  align-items: start;
}
input.link-input {
  line-height: 30px;
  font-size: 0.8em;
  text-indent: 5px;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
  border: none;
  border-bottom: 3px solid #995dd4;
  outline: 0;
}
.quill-input {
  flex-grow: 1;
  line-height: 30px;
  font-size: 0.8em;
  text-indent: 5px;
  padding: 5px;
  margin: 5px;
  border: none;
  border: 2px solid #995dd4;
  border-radius: 10px;
  outline: 0;
}
.component-container {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.section-menu {
  display: flex;
  justify-content: flex-row;
  font-size: 0.8em;
}
.plus-icon {
  display: flex;
  justify-content: center;
  alight-item: center;
  font-size: 2em;
  font-weight: bold;
}
.component-border {
  border: 1px solid #dfdfdf;
  padding: 10px;
  margin: 10px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
input {
  display: none;
}
.component-file-upload {
  display: block;
  margin: 5px;
  width: 26px;
  height: 24px;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-image: url('../assets/upload-icon-small.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.quill-input {
  flex-grow: 1;
  line-height: 30px;
  font-size: 0.8em;
  text-indent: 5px;
  padding: 5px;
  margin: 5px;
  border: none;
  border: 2px solid #995dd4;
  border-radius: 10px;
  outline: 0;
}
.component-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.component-image img {
  border: 3px solid #cfcfcf;
  border-style: dashed;
  object-fit: contain;
}
.component-text {
  margin: 20px;
}
.cmp-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
}
.cmp-row-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.cmp-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cmp-row-reverse {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
.cmp-row-reverse-wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap
}
.cmp-column-reverse {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.component-controls {
  width: auto;
  margin: 10px;
  background: #82929d;
  border-radius: 5px;
  display: flex;
  z-index: 0;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
}
.inherit {
  display: flex;
  justify-content: center;
  width: inherit;
  height: inherit;
  min-width: 50%;
}
.component-video {
  width: 100%;
}
.relative {
  width: 100%;
  position: relative;
}
.component-background-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.component-background-video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: covert;
  z-index: -1;
}
.launcher {
  width: 100%;
}
.minter {
  width: 100%;
}
.normal-link {
  color: white;
  margin: 5px;
  font-size: 0.8em;
  cursor: pointer;
  font-weight: 400;
}
.normal-link:hover {
  text-decoration: underline;
}
.set-image {
  display: flex;
  justify-content: row;
}
.margin-ten {
  margin: 10px;
}
</style>
