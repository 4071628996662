<template>
  <div
    v-if="isLang('ja') > -1"
    :class="docMain"
  >
    <head>
      <title />
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <div>
      <h1 class="doc">
        シノビについて
      </h1>
      Shinoviは、株式会社マツモトとcompdeepの共同プロジェクトで、日本文化と世界の架け橋となるべく誕生しました。Shinoviは、Web3技術を駆使して、日本のアート、マンガ、サブカルチャーなどを世界中の人々に紹介し、新しい規模の経済を構築していきます。
      <br>
      <br>

      株式会社マツモトは、日本の九州地方を代表する印刷会社であり、品質と革新に強いこだわりを持っています。一方、compdeepは、東京に拠点を置くディープテックのWeb3開発会社で、最先端技術の構築を得意としています。
      <br>
      <br>

      <div class="shinovi-poster" />
      <br>
      シノビは日本のゲートウェイとして、日本の文化やクリエイターを海外に発信しています。アート、マンガ、ファッション、サブカルチャーなど、日本には多くの魅力があり、それをより多くの人と共有したいと私たちは考えています。web3テクノロジーの力を借りれば、従来の垣根を取り払い、よりつながりのある世界を作ることができるのです。
      <br>
      <br>

      Shinoviという名前は、日本の伝統文化に深く根付いている忍者の概念「忍」にインスパイアされています。Shinoviは、日本文化を世界に広めるために、速く、機敏に、そしてステルス的に活動するプラットフォームになると信じて、この名前を選びました。
      <br>
      <br>

      Shinoviでは、日本のクリエイターやアーティストのプロモーションに情熱を持って取り組んでいます。彼らの作品はユニークで価値があると信じていますし、彼らが活躍できる環境を作りたいと考えています。私たちの目標は、日本文化への愛を共有するクリエイター、ファン、愛好家による活気あるコミュニティを構築することです。
      <br>
      <br>

      私たちは、このエキサイティングな旅に参加し、シノビの世界を探検するよう、あなたを招待します。自分の作品を紹介したいクリエイター、新しくエキサイティングなコンテンツを探しているファン、そして単に日本文化についてもっと知りたい人など、すべての人が楽しめる内容になっています。
      <br>
      <br>
      <br>
    </div>
  </div>
  <div
    v-else
    :class="docMain"
  >
    <head>
      <title />
    </head>
    <div>
      <h1 class="doc">
        About ShnoVi
      </h1>
      Welcome to Shinovi, a joint project between Matsumoto K.k and compdeep, two innovative companies coming together to build a bridge between Japanese culture and the world. Shinovi utilizes web3 technology to bring together the best of Japanese art, manga, subculture, and more to a global audience, building a new economy of scale.
      <br>
      <br>

      Matsumoto K.k is a leading printing company from the Kyushu region of Japan, with a strong focus on quality and innovation. compdeep, on the other hand, is a deeptech web3 development company based in Europe and Tokyo, with expertise in building cutting-edge technologies.
      <br>
      <br>

      <div class="shinovi-poster" />
      <br>
      <br>
      Shinovi is a gateway to Japan, promoting Japanese culture and creators to the rest of the world. We believe that Japan has a lot to offer in terms of art, manga, fashion, and subculture, and we want to share it with as many people as possible. With the help of web3 technology, we can break down traditional barriers and create a more connected world.
      <br>
      <br>

      The name Shinovi is inspired by the ninja concept of "shinobi", which is deeply rooted in Japanese traditional culture. We chose this name because we believe that Shinovi will be a platform that is fast, agile, and stealthy in promoting Japanese culture to the world.
      <br>
      <br>

      At Shinovi, we are passionate about promoting Japanese creators and artists. We believe that their work is unique and valuable, and we want to create an environment where they can thrive. Our goal is to build a vibrant community of creators, fans, and enthusiasts who share a love for Japanese culture.
      <br>
      <br>

      We invite you to join us on this exciting journey and explore the world of Shinovi. Whether you are a creator looking to showcase your work, a fan looking for new and exciting content, or someone who simply wants to learn more about Japanese culture, we have something for everyone.
      <br>
      <br>
      <br>
    </div>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Privacy",
    "components": {
    },
    "props": {
    },
    setup () {

        const icon = iconStore(),
            serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            "icon": icon.get,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  line-height: 50px;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-poster {
  height: 1000px;
  background-image: url('https://compdeep.com/img/shinovi.801d0d87.png');
  background-repeat: no-repeat;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>
