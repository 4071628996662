<template>
  <div>
    <div
      v-cloak
      :class="homeCarouselContainer"
    >
      <Transition>
        <Carousel
          v-if="loadCarousel"
          id="gallery"
          v-model="currentSlide"
          :wrap-around="true"
          :items-to-show="itemsToShow"
          :autoplay="50000000"
          :translition="500"
          :pause-autoplay-on-hover="true"
        >
          <Slide
            v-for="(i,j) in creatorfolios"
            :key="j"
          >
            <CreatorFolio
              :title="i.title"
              :about="i.about"
              :image="i.image"
              :video="i.video"
              :video-thumbnail="i.videoThumbnail"
              :effect="i.effect"
              :primary-color="i.primaryColor"
              :secondary-color="i.secondaryColor"
              :refresh="i.refresh"
              :n="i.n"
              class="folio-style"
              @click="gotoTab(i.name)"
            />
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </Transition>

      <!--

        <Carousel
          v-if="loadCarousel"
          id="thumbnails"
          ref="carousel"
          v-model="currentSlide"
          :wrap-around="true"
          :items-to-show="5"
        >
          <Slide
            v-for="(i,j) in creatorfolios"
            :key="i.n"
          >
            <div
              v-if="i.image"
              class="carousel-item"
              @click="slideTo(j)"
            >
              <img
                :class="carouselItem"
                :src="i.image"
              >
            </div>
            <div
              v-else-if="i.video"
              @click="slideTo(j)"
            >
              <video
                autoplay
                muted
                loop
                :class="carouselItem"
                :poster="i.videoThumbnail"
              >
                <source
                  :src="i.video"
                  type="video/mp4 "
                >
              </video>
            </div>
          </Slide>
        </Carousel>

-->

      <div
        v-if="serverConfig.view === 'desktop' || serverConfig.view === 'laptop'"
        class="shinovi-position-0"
      >
        <div :class="genRandSymbolClass()" />
      </div>

      <div
        v-if="serverConfig.view === 'desktop' || serverConfig.view === 'laptop'"
        class="home-header-links"
      >
        <div
          v-for="(i,j) in creatorfolios"
          :key="i.n"
          :class="homeHeaderLink(j)"
        >
          {{ i.title }}
        </div>
      </div>

      <div class="shinovi-position-2">
        <div :class="genRandSymbolClass()" />
      </div>
    </div>

    <div
      v-if="serverConfig.view != 'mobile'"
      class="home-news-container-desktop"
    >
      <div
        v-for="(i,j) in news"
        @click="gotoUrl(i.link)"
        class="home-news-absolute"
      >
        <transition
          :duration="300"
          name="news-slide-fade"
        >
          <div
            v-if="displayNewsItem == i.id && i.title"
            class="flex-row home-news-container-inner"
          >
            <img
              class="home-news-image"
              :src="'../public/images/news_item_' + i.id + '.png'"
            >
            <div :class="newsText">
              <div>
                <b>{{ i.title }}</b>
              </div>
              <div>
                {{ i.text }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="home-header">
      {{ localize('TEXT_MINTING_NOW') }}
    </div>

    <div class="home-mint-container"
      v-if="serverConfig.view === 'desktop' || serverConfig.view === 'laptop'"
    >

      <div class="flex-row-mint">

        <div
          @click="gotoTab('mint/0xbAEEe7D93D99E4730b2E9f3aE30E208248eBD1C7')"
          class="promo-link-a"
        />

        <div
          @click="gotoTab('mint/0xAd7db43064Cca31DdDd568684a259AF685c06657')"
          class="promo-link-b"
        />

      </div>

      <div class="flex-row-mint">

        <div
          @click="gotoTab('mint/0x6DE97A306144ff1231A68b960c4427c56F88b0f5')"
          class="promo-link-c"
        />

        <div
          @click="gotoTab('mint/0x8Ca0da1f7dE74Eb1BF29cA0AcC80740441637624')"
          class="promo-link-d"
        />

      </div>

    </div>
    <div v-else class="home-mint-container">

        <div
          @click="gotoTab('mint/0x6DE97A306144ff1231A68b960c4427c56F88b0f5')"
          class="promo-link-a"
        />

        <div
          @click="gotoTab('mint/0x8Ca0da1f7dE74Eb1BF29cA0AcC80740441637624')"
          class="promo-link-b"
        />

        <div
          @click="gotoTab('mint/0x6DE97A306144ff1231A68b960c4427c56F88b0f5')"
          class="promo-link-c"
        />

        <div
          @click="gotoTab('mint/0x8Ca0da1f7dE74Eb1BF29cA0AcC80740441637624')"
          class="promo-link-d"
        />

    </div>

    <div class="home-header">
      {{ localize('TEXT_TRENDING_NFTS') }}
    </div>

    <!--
    <div :class="genRandPositionClass()">
      <div :class="genRandSymbolClass()" />
    </div>
-->
    <div
      v-if="serverConfig.windowWidth > 500"
      class="home-card-wrapper"
    >
      <NFTCard
        v-for="(n,i) in homeTokens"
        :key="n.token_id"
        styling="small"
        :address="n.nft_address"
        :owner="n.owner"
        :price="n.price"
        :symbol="n.symbol"
        :metadata="n.metadata"
        :metadata_url="n.token_uri"
        :token_id="n.token_id"
        :chain_id="n.chain_id"
        @reload-token="getTokens(n.nft_address)"
      />
    </div>
    <div
      v-else
      class="home-line-wrapper"
    >
      <NFTLineItem
        v-for="(n,i) in homeTokens"
        :key="n.token_id"
        styling="small"
        :address="n.nft_address"
        :owner="n.owner"
        :price="n.price"
        :symbol="n.symbol"
        :metadata="n.metadata"
        :metadata_url="n.token_uri"
        :token_id="n.token_id"
        :chain_id="n.chain_id"
        @reload-token="getTokens(n.nft_address)"
      />
    </div>

    <div :class="genRandPositionClass()">
      <div :class="genRandSymbolClass()" />
    </div>

    <div class="home-header">
      New Collections
    </div>

    <div :class="homeCollectionsContainer">
      <div
        v-for="a in collections"
        :class="homeCollectionItem"
        @click="gotoCollection(a.nft_address)"
      >
        <div :class="genRandPositionClass()">
          <div :class="genRandSymbolClass()" />
        </div>
        <CollectionPreview
          class="mid-section"
          :collection="a"
        />
      </div>
    </div>

    <Transition>
      <CModal
        v-if="showModal"
        color="#f9b115"
        title="Sign up."
        size="small"
        @close="showModal = false"
      >
        <template #header>
          <div> Sign Up </div>
        </template>
        <template #body>
          <Login color="#47357378" />
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>
</template>
<script>

import {Carousel, Navigation, Pagination, Slide, toRaw} from "vue3-carousel";
import {inject, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {translationStore} from "@/stores/translation";
import Login from "./Login.vue";
import CreatorFolio from "./CreatorFolio.vue";
import CollectionPreview from "./CollectionPreview.vue";
import Axios from "axios";

export default {
    "name": "Home",
    "components": {
        Login,
        CreatorFolio,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        CollectionPreview
    },
    setup () {

        const translation = translationStore(),
            loadCarousel = ref(false),
            itemsToShow = ref("1.1"),
            displayNewsItem = ref(1),
            creatorFolio = ref(),
            creatorfolios = reactive([]),
            collections = reactive([]),
            homeCollectionsContainer = ref("home-collections-container-desktop"),
            homeCollectionItem = ref("home-collection-item-desktop"),
            news = reactive([]),
            newsText = ref("news-text"),
            homeTokens = reactive([]),
            serverConfig = inject("serverConfig"),
            showModal = ref(false),
            homeCarouselContainer = ref("home-carousel-container-desktop"),
            carouselItem = ref("carousel-item-desktop"),
            currentSlide = ref(0),
            route = useRoute(),
            router = useRouter(),
            slideTo = function (v) {

                currentSlide.value = v;

            },
            gotoTab = function (t) {

                router.push({"path": `/${t}/`}).catch((err) => {

                    throw err;

                });

            },
            gotoUrl = function (t) {

                window.open(t, '_blank');

            },
            gotoCollection = function (address) {

                router.push({"path": `/collection/${address}/`}).catch((err) => {

                    throw err;

                });

            },
            getNews = function () {

                news.splice(0);
                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getNews`,
                    {
                    }
                ).
                    then((response) => {

                        for (const n in response.data) {

                            news.unshift(response.data[n]);

                        }

                    });

            },
            getTokens = function () {

                homeTokens.splice(0);
                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getTrendingTokens`,
                    {
                    }
                ).
                    then((response) => {

                        for (const n in response.data) {

                            homeTokens.push(response.data[n]);

                        }

                    });

            },
            genRandSymbolClass = function () {

                const s = Math.floor(Math.random() * 6) + 1,
                    e = Math.floor(Math.random() * 6) + 1;
                return `shinovi-symbol-${s} ` + `shinovi-effect-${e}`;

            },
            genRandPositionClass = function () {

                const p = Math.floor(Math.random() * 6) + 1;
                return `shinovi-position-${p}`;

            },
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            settings = ref({

                /*
                 * autoplay:true,
                 * autoplayTimeout:5000,
                 * transition:500
                 */
            }),
            homeHeaderLink = function (j) {

                if (j == 3) {

                    return "home-header-link-right";

                }

                return "home-header-link-left";


            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    newsText.value = "news-text-desktop";
                    carouselItem.value = "carousel-item-desktop";
                    homeCarouselContainer.value = "home-carousel-container-desktop";
                    homeCollectionsContainer.value = "home-collections-container-desktop";
                    homeCollectionItem.value = "home-collection-item-desktop";
                    itemsToShow.value = "1.1";

                } else if (serverConfig.view === "laptop") {

                    newsText.value = "news-text-desktop";
                    carouselItem.value = "carousel-item-desktop";
                    homeCarouselContainer.value = "home-carousel-container-desktop";
                    homeCollectionsContainer.value = "home-collections-container-desktop";
                    homeCollectionItem.value = "home-collection-item-desktop";
                    itemsToShow.value = "1.1";

                } else if (serverConfig.view === "tablet") {

                    carouselItem.value = "carousel-item-desktop";
                    newsText.value = "news-text-mobile";
                    homeCarouselContainer.value = "home-carousel-container-mobile";
                    homeCollectionsContainer.value = "home-collections-container-mobile";
                    homeCollectionItem.value = "home-collection-item-mobile";
                    itemsToShow.value = "1.0";

                } else if (serverConfig.view === "mobile") {

                    newsText.value = "news-text-mobile";
                    carouselItem.value = "carousel-item-mobile";
                    homeCarouselContainer.value = "home-carousel-container-mobile";
                    homeCollectionsContainer.value = "home-collections-container-mobile";
                    homeCollectionItem.value = "home-collection-item-mobile";
                    itemsToShow.value = "1.0";

                } else {

                    newsText.value = "news-text-mobile";
                    carouselItem.value.view = "carousel-item-mobile";
                    homeCarouselContainer.value = "home-carousel-container-mobile";
                    homeCollectionsContainer.value = "home-collections-container-mobile";
                    homeCollectionItem.value = "home-collection-item-mobile";
                    itemsToShow.value = "1.0";

                }

            },
            getFolios = function (e) {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getFolios`,
                    {
                        "params": {
                        }
                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            for (const j in response.data) {

                                if (response.data[j].image || response.data[j].video) {

                                    creatorfolios.push(response.data[j]);
                                    creatorfolios[creatorfolios.length - 1].primaryColor = response.data[j].primary_color;
                                    creatorfolios[creatorfolios.length - 1].secondaryColor = response.data[j].secondary_color;
                                    creatorfolios[creatorfolios.length - 1].n = genRandStr();
                                    creatorfolios[creatorfolios.length - 1].refresh = currentSlide.value;

                                }

                            }

                            loadCarousel.value = true;

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getCollections = function (e) {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getCollections`,
                    {
                        "params": {
                        }
                    }

                ).
                    then((response) => {

                        if (response.data) {

                            for (let i = 0; i < response.data.length; i++) {

                                if (response.data[i].image && response.data[i].name && response.data[i].symbol && response.data[i].banner) {

                                    collections.push(response.data[i]);
                                    collections[collections.length - 1].primaryColor = response.data[i].primary_color;
                                    collections[collections.length - 1].secondaryColor = response.data[i].secondary_color;

                                }

                            }

                        }

                    });

            };
        onMounted(() => {

            getCollections();
            updateView();

            watch(
                () => currentSlide.value,

                (first, second) => {

                    if (creatorfolios[first]) {

                        creatorfolios[first].refresh = currentSlide.value;

                    }

                }

            );

            setTimeout(
                () => {

                    getFolios();
                    getNews();
                    getTokens();

                },
                100
            );

            const runNews = function () {

                setTimeout(
                    () => {

                        if (displayNewsItem.value === 5) {

                            displayNewsItem.value = 0;

                        }

                        displayNewsItem.value++;
                        runNews();

                    },
                    5000
                );

            };
            runNews();

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            genRandStr,
            gotoUrl,
            gotoTab,
            gotoCollection,
            genRandSymbolClass,
            genRandPositionClass,
            loadCarousel,
            homeCarouselContainer,
            homeHeaderLink,
            homeCollectionsContainer,
            homeCollectionItem,
            settings,
            collections,
            news,
            newsText,
            getNews,
            homeTokens,
            displayNewsItem,
            creatorfolios,
            creatorFolio,
            getTokens,
            getFolios,
            getCollections,
            itemsToShow,
            showModal,
            updateView,
            currentSlide,
            carouselItem,
            slideTo,
            serverConfig};

    }
};

</script>
<style scoped>
.separado {
  width: 20px;
  height: 20px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.carousel-item {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px;
}
img.carousel-item-desktop {
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  max-width: 200px;
  aspect-ratio: 1.4;
  object-fit: cover;
  margin: 20px;
  box-shadow: 0px 5px 20px 0px rgb(110 94 136);
}
video.carousel-item-desktop {
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  max-width: 200px;
  aspect-ratio: 1.4;
  object-fit: cover;
  margin: 20px;
  box-shadow: 0px 5px 20px 0px rgb(110 94 136);
}
img.carousel-item-mobile {
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  max-width: 150px;
  aspect-ratio: 1.4;
  object-fit: cover;
  background-color: grey;
  margin: 20px;
  box-shadow: 0px 5px 20px 0px rgb(110 94 136);
}
video.carousel-item-mobile {
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  max-width: 150px;
  aspect-ratio: 1.4;
  object-fit: cover;
  background-color: grey;
  margin: 20px;
  box-shadow: 0px 5px 20px 0px rgb(110 94 136);
}
.titletext {
  text-align: left;
  font-size: 2.0em;
  font-weight: bold;
}
.titletextcyan {
  text-align: left;
  font-size: 2.0em;
  font-weight: bold;
  color: #1FB6DC;
}
.comingsoonlogo {
  background-size:contain;
  background-position: 70%;
  width: 100%;
  height: 400px;
}
.comingsoontext {
  color:black;
  font-size: 1.2em;
  margin-top: -100px;
}
.home-header {
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  padding-left: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 4px 4px 0 #925ab1, 1px -1px 0 #925ab1, -1px 1px 0 #925ab1, 1px 1px 0 #925ab1;
}
.home-list {
  color: black;
  margin-top: 10px
}
.home-head-desktop {
  font-size: 1.0em;
  text-align: left;
  line-height: 30px;
  margin: 10px;
  color: #908AB9;
}
.home-head-mobile {
  margin-top: 10px;
  font-size: 1.0em;
  width: 250px;
  color: #908AB9;
}
.relative {
  position: relative;
}
#ethicaladDesktop {
  max-width: 500px;
  min-width: 250px;
  margin-left: 100px;
  margin-top: 200px;
  flex: 1 1 0px;
}
#ethicaladMobile {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  flex: 1 1 0px;
}
.home-carousel-container-desktop {
  position: relative;
  height: 100%;
  min-height: 720px;
  margin-left: 30px;
  margin-right: 30px;
}
.home-carousel-container-mobile {
  position: relative;
  height: 100%;
  min-height: 820px;
}
.desktop-margin {
  margin-left: 100px;
}
.construction {
  font-family: Comfortaa,sans-serif;
  text-align: left;
  color: #ad8bfa;
  height: 50px;
  max-width: 550px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  line-height: 30px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
}
.itemtext {
  width: 100%;
  margin: 10px;
  text-align: left;
}
.itemcontainer {
  margin: 20px;
}
.item-title {
  margin: 10px;
  font-weight: bold;
}
.fill {
  position: relative;
  width: 100%;
}
ul {
  margin: 10px;
}
li {
  margin: 0px;
}
.content-icon {
  margin-top: 20px;
  background-color: #937aeb;
  padding: 10px;
  border-radius: 50%;
  width: 100px;
}
.content-container {
  line-height: 30px;
  text-align: left;
  margin: 20px;
}
.hund {
  width: 100%;
}
.home-collection-item-desktop {
  width: 50%;
}
.home-collection-item-mobile {
  width: 100%;
}
.home-collections-container-desktop {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1600px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.home-collections-container-mobile {
  position: relative;
  display: column;
  flex-direction: row;
  flex-wrap: no-wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.home-header-links {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.home-header-link-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  margin-right: 30px;
  max-height: 60px;
  max-height: 40px;
  color: #b19fff;
  background: #f2effd;
  padding: 10px;
  overflow: hidden;
}
.home-header-link-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  max-height: 60px;
  max-height: 40px;
  color: #b19fff;
  background: #f2effd;
  padding: 10px;
  overflow: hidden;
}
.grow {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
.headerDesktop {
  max-width: 650px;
  margin: 10px;
  text-align: left;
  color: #322E6A;
}
.headerMobile {
  margin: 20px;
  max-width: 500px;
  font-size: 1em;
  text-align: center;
  color: #322E6A;
  z-index: 1;
}
.startHeader {
  width: 55%;
  padding: 10px;
  display: block;
  margin-top: 150px;
  margin-left: 20px;
  color: #322E6A;
  background-color: hsl(0deg 0% 100% / 70%);
}
.fifty {
  width: 50%;
}
svg {
  width: 100%;
  max-height: 560px;
  z-index: 1;
}
img.creator-img-desktop {
  width: 100%;
  padding-left: 25%;
  z-index: -2;
}
img.creator-img-mobile {
  width: 100%;
  padding-left: a5%;
  z-index: -2;
}
#home {
  min-height: 1000px;
}
.spacer {
  width: 20px;
}
.mid-section {
  margin: 25px;
  justify-content: center;
  cursor: pointer;
}
/*
  background-image: url('../assets/12-req.body.symbol.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
*/
.text-left {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 60%;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  padding-top: 100px;
}
.text-top {
  position: absolute;
  display: flex;
  align-items: end;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 100px;
  text-align: left;
}
.folio-style {
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}
.home-news-container-desktop {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 700px;
  width: 100%;
  height: 250px;
  display: flex;
  background-image: url("../assets/news_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
}
.home-news-absolute {
  position: absolute;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.home-news-container-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  height: 250px;
  display: flex;
}
.home-news-carousel {
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-news-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.home-news-image {
  height: 128px;
  margin: 10px;
  background-size: contain;
}
.home-card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 750px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.home-line-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.news-text-mobile {
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-height: 200px;
  max-width: 300px;
  font-size: 0.8em;
}
.news-text-desktop {
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-height: 250px;
  max-width: 400px;
  font-size: 0.9em;
}
.news-slide-fade-enter-active {
  transition: all .3s ease;
}
.news-slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.news-slide-fade-enter-from {
  transform: translateX(-500px);
  opacity: 0;
}
.news-slide-fade-leave-to {
  transform: translateX(500px);
  opacity: 0;
}
.carousel__slide {
  display: flex;
  align-items: start;
}

</style>
<style>
svg.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: white;
  background-color: #b19fff !important;
  color: white !important;
  border-radius: 50% !important;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 40px !important;
  min-height: 40px !important;
  transition: all;
  transition-delay: 1s;
}
svg.carousel__icon:hover {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: #b19fff;
  background-color: #white !important;
  color: #b19fff !important;
}
button.carousel__next {
  margin-right: 20px;
}
button.carousel__prev {
  margin-left: 20px;
}
.carousel {
  touch-action: unset !important;
}
.carousel__track {
  touch-action: unset !important;
}
.figma-1 {
  position: absolute;
  width: 1150.73px;
  height: 920.48px;
  left: -264.36px;
  top: 128.79px;
 /* background: linear-gradient(180deg, #FF006B 0%, #FF6DC1 100%);*/
  filter: blur(200px);
}
.figma-2 {
  position: absolute;
  width: 987px;
  height: 369px;
  left: 903.55px;
  top: 1565.73px;
  /*background: #6233a92b;*/
  filter: blur(200px);
  transform: rotate(-14.26deg);
}
.figma-3 {
  position: absolute;
  width: 1150.73px;
  height: 920.48px;
  left: 1107.64px;
  top: 1280.79px;
 /* background: linear-gradient(180deg,#ff006b33,#ff6dc147);*/
  filter: blur(200px);
}
.figma-4 {
  position: absolute;
  width: 831px;
  height: 831px;
  left: 781.55px;
  top: 1905.65px;
  background: #00e5ff29;
  filter: blur(200px);
}
.promo-link-a {
  height: 100%;
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  background: url(https://www.shinovi.io/public/images/shunyodo-promo.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.promo-link-b {
  height: 100%;
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  background: url(https://www.shinovi.io/public/images/mizu.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.promo-link-c {
  height: 100%;
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  background: url(https://www.shinovi.io/public/images/0x6DE97A306144ff1231A68b960c4427c56F88b0f5.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.promo-link-d {
  height: 100%;
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  background: url(https://www.shinovi.io/public/images/0x8Ca0da1f7dE74Eb1BF29cA0AcC80740441637624.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
@keyframes spin-1{
  100% { transform: rotate(-360deg); }
}
.home-mint-container {
  display: flex;
  flex-direction: column;
  height: 450px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.flex-row-mint {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.shinovi-frills {
  position: relative;
  overflow: hidden;
}
.shinovi-position-0 {
  position: absolute;
  display: flex;
  justify-content: start;
  height: 200px;
  width: 100%;
  max-width: 100%;
  margin-top: 70px;
  z-index: -1;
  transform: translateX(100px);
}
.shinovi-position-1 {
  position: absolute;
  display: flex;
  justify-content: start;
  height: 200px;
  width: 100%;
  max-width: 100%;
  margin-top: -100px;
  z-index: -1;
  transform: translateX(100px);
}
.shinovi-position-2 {
  position: absolute;
  display: flex;
  justify-content: end;
  height: 200px;
  width: 100%;
  max-width: 100%;
  margin-top: 200px;
  z-index: -1;
  transform: translateX(-50px);
}
.shinovi-position-3 {
  position: absolute;
  display: flex;
  justify-content: start;
  height: 200px;
  width: 100%;
  max-width: 100%;
  margin-top: 280px;
  z-index: -1;
  transform: translateX(-80px);
}
.shinovi-position-4 {
  position: absolute;
  display: flex;
  justify-content: end;
  height: 200px;
  width: 100%;
  max-width: 100%;
  margin-top: 200px;
  z-index: -1;
  transform: translateX(110px);
}
.shinovi-position-5 {
  position: absolute;
  display: flex;
  justify-content: start;
  height: 200px;
  width: 100%;
  max-width: 100%;
  margin-top: 200px;
  z-index: -1;
  transform: translateX(50px);
}
.shinovi-position-6 {
  position: absolute;
  display: flex;
  justify-content: end;
  height: 200px;
  width: 100%;
  margin-top: 0px;
  max-width: 100%;
  z-index: -1;
  transform: translateX(50px);
}
.shinovi-symbol-0 {
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/shinovi-symbol-1.png");
  background-image: url("../assets/shinovi-symbol-1.png");
  z-index: -1;
}
.shinovi-symbol-1 {
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/shinovi-symbol-1.png");
  z-index: -1;
}
.shinovi-symbol-2 {
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/shinovi-symbol-2.png");
}
.shinovi-symbol-3 {
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/shinovi-symbol-3.png");
}
.shinovi-symbol-4 {
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/shinovi-symbol-4.png");
}
.shinovi-symbol-5 {
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/shinovi-symbol-5.png");
}
.shinovi-symbol-6 {
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/shinovi-symbol-6.png");
}
.shinovi-effect-0 {
  animation: spin-1  cubic-bezier(0.1, -1.5, 0.5, 0) infinite;
  animation-duration: 40s;
}
.shinovi-effect-1 {
  animation: spin-1  cubic-bezier(0.1, -0.6, 0.2, 0) infinite;
  animation-duration: 40s;
}
.shinovi-effect-2 {
  animation: spin-1 cubic-bezier(0.1, -0.6, 0.2, 0) infinite;
  animation-duration: 50s;
}
.shinovi-effect-3 {
  animation: spin-1 cubic-bezier(0.2, -0.2, 0.6, 0) infinite;
  animation-duration:111s;
}
.shinovi-effect-4 {
  animation: spin-1 cubic-bezier(0.1, -1.6, 0.3, 0) infinite;
  animation-duration:88s;
}
.shinovi-effect-5 {
  animation: spin-1 cubic-bezier(0.2, -0.5, 0.5, 0) infinite;
  animation-duration: 66s;
}
.shinovi-effect-6 {
  animation: spin-1 125s linear infinite;
  animation-duration:100s;
}
</style>
