<template>
  <div
    v-if="isLang('ja') > -1"
    :class="docMain"
  >
    <head>
      <title>マツモト様_個人情報保護方針</title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <div>
個人情報保護方針

当社は、アルバム製作の総合印刷及びNFT（Non-Fungible Token）のマーケットプレイス運営業務を営む会社として、お客様の個人情報がプライバシーを構成する重要な情報であることを深く認識し、業務において個人情報を取り扱う場合には、個人情報に関する法令及び個人情報保護のために定めた社内規定を定め、また、組織体制を整備し、個人情報の適切な保護に努めることにより、お客様を尊重し、当社に対する期待と信頼に応えていきます。


</div>
<div>

個人情報の取得、利用、提供
当社は、事業活動の範囲内で個人情報の利用目的を特定し、その目的達成のために必要な限度で公正かつ適正に個人情報の取得、利用及び提供を行います。また、取得した個人情報の目的外利用をしないよう処置を講じます。


</div>
<div>
法令・規範の遵守
当社は、個人情報に関する法令、国が定める指針、その他の規範及び社会秩序を遵守し、個人情報の適切な保護に努めます。

</div>
<div>
　
個人情報の適切な管理
当社は、私たちが取り扱う個人情報について、不正アクセス、紛失、破壊、改ざん、漏えいなどの危険を十分に認識し、合理的な安全対策を実施するとともに、問題が発生した場合は適切な是正措置を講じます。


</div>
<div>
問い合わせへの対応
当社は、私たちが取り扱う個人情報について、本人から開示、訂正、利用停止及び苦情相談等のお問い合わせがあった場合は適正に対応します。

</div>
<div>

継続的改善
当社は、個人情報保護に関する管理規定及び管理体制を整備し、全社員で徹底して運用するとともに定期的な見直しを行い、継続的な改善に努めます。

</div>
<div>

保有個人データの安全管理のために講じられた措置
(基本方針の策定)
個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「個人情報問合せ窓口」等についての基本方針を策定しています。

</div>
<div>

(個人データの取扱いに係る規律の整備)
取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人データの取扱規程を策定しています。

</div>
<div>

(組織的安全管理措置)
個人データの取扱いに関する責任者を設置するとともに、個人データを取り扱う従業者及び当該従業者が取り扱う個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しています。


</div>
<div>

(人的安全管理措置)
個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しています。
また、個人データについての秘密保持に関する事項を就業規則に記載しています。

</div>
<div>

(物理的安全管理措置)
個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を実施しています。
個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を実施しています。

</div>
<div>

(技術的安全管理措置)
アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しています。
個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。

</div>
<div>

外国にある第三者への提供
当社は、外国にある第三者に個人データの提供を行う場合は、法令の定めに従い、同意の取得等必要な措置を講じます。

</div>
<div>

保有個人データの取り扱いに関する請求対応
当社は、個人情報の照会、訂正、利用停止、消去等のご要望があったときは、所定の手続で利用者本人であることを確認の上、速やかに対応します。本プライバシーポリシーに関して開示請求等をされる場合は、当社の管理責任者にご連絡ください。

</div>
<div>

　H23年6月2日　制定
R6年1月8日　改定

</div>
<div>

株式会社マツモト
代表取締役　松本 大輝
住所：　福岡県北九州市門司区社ノ木1-2-1

</div>
<div>

＜個人情報保護に関する連絡先＞                   
個人情報問合せ窓口
個人情報保護管理者 德永和敏
　　　　　　　　　　　　　　　　　TEL 093-971-0298

</div>
<div>


当社が取扱う個人情報について
当社では「個人情報保護方針」に基づき個人情報の適切な保護に取り組んでいます。当社が事業の用に供するために取得し、または保有する個人情報について、以下の通りお知らせいたします。
（１）個人情報の取扱事業者の名称
株式会社マツモト


</div>
<div>

（２）個人情報保護管理者（若しくはその代理人）の氏名又は職名、所属及び連絡先
　　　個人情報保護管理者：德永 和敏
　　　電話番号：093-371-0298


</div>
<div>

（３）個人情報の利用目的
当社が事業活動において取扱う個人情報の利用目的は、次の通りといたします。
①開示対象個人情報
個人情報の種別
利用目的
取引先情報
業務管理、各種連絡、請求、支払い管理のため
従業者情報
従業者管理に係わる業務に利用するため（業務・労務・人事管理業務、給与関連業務、福利厚生業務など）
採用応募者情報
採用に係わる業務に利用するため（採用に関する情報提供、採用可否判断、採用業務に関する連絡など）
退職者情報
退職者との連絡、退職者からのお問合せへの対応に利用するため
お問合せ者情報
お問合せに回答するため
クリエイター情報
NFTマーケットプレイスにおけるサービス提供その他当社の業務を提供するため
本人および代理人の情報（開示等請求時）
開示等の求めに回答するため

その他、個別に書面で明示したとおりの利用目的とします。


</div>
<div>

②それ以外取得個人情報
個人情報の種別
利用目的
受託した業務により取得した個人情報
・卒業アルバム、記念誌、文集等の印刷、発送のため
・卒業アルバム、記念誌、文集等のＣＤ製作のため
・ダイレクトメールの印刷、発送のため
・各種名簿の印刷、発送のため
・その他、個人情報の加工、印刷、発送に関わる業務のため





</div>
<div>

（４）個人情報に関するお問合せ窓口
開示対象個人情報に関わるものを含め、個人情報の取扱についての苦情・相談、問合せは、
下記の【問合せ窓口】までお申し出ください。


</div>
<div>

（５）所属する認定個人情報保護団体の名称および苦情の解決の申し出先
【認定個人情報保護団体の名称】 
一般財団法人日本情報経済社会推進協会 


</div>
<div>

【苦情解決の連絡先】 
認定個人情報保護団体事務局
〒106-0032 東京都港区六本木一丁目９番９号 六本木ファーストビル内
03-5860-7565　／　0120-700-779

</div>
<div>

（６）開示等の手続について
当社では、開示対象個人情報に関して、ご本人の開示等（利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止）の請求に迅速に対応いたします。希望される場合には、お申し出いただいた方がご本人或いはその代理人であることを確認した上で、合理的な期間及び範囲で対応いたします。



</div>
<div>
（７）開示等の受付方法・窓口
開示対象個人情報に関する開示等のお申し出は、下記の受付窓口までご連絡ください。ご連絡いただきましたら、当社所定の「保有個人データ開示等請求書」を郵送または、FAX、メール等でお送り致しますので、記入の上、必要書類をメールまたは郵送にてお送り下さい。（送付料は請求者のご負担となります。）
ご本人（または代理人）であることを確認した上で、書面の交付または電磁的記録により回答いたします。保有個人データ等の開示請求の場合で、電磁的記録による回答をご希望の場合は、請求等いただいた方が指定するe-mailアドレス宛に電磁的記録を送信する方法によって回答いたします。


</div>
<div>

（８）開示等の受付方法・窓口
当社は、次に掲げる場合を除くほか、あらかじめご本人様の同意を得ないで、保有する個人データを第三者に開示又は提供しません。なお、お客様が当社の運営するNFTマーケットプレイスを通じて、デジタルチケットを表章するＮＦＴを購入した場合、当該デジタルチケットによって参加することのできるイベントの主催者等に対して、お客様の同意を得て一定のお客様の個人データを提供すること又はお客様から直接イベントの主催者等に対してご自身の個人情報をご提供いただくことがあります。

</div>
<div>

【問合せ窓口】
窓口の名称
個人情報問合せ窓口
連絡先
窓口責任者：德永 和敏
住所　　  ：福岡県北九州市門司区社ノ木1-2-1
電話　　　 ：093-371-0298

    </div>
  </div>
  <div
    v-else
    :class="docMain"
  >
    <head>
      <title>マツモト様_個人情報保護方針</title>
    </head>
    <div>
      <h1 class="doc">
        Personal Information Protection Policy
      </h1>
      At our company, we are dedicated to providing general printing services, with a focus on album production and NFT (Non-Fungible Token) marketplace operations. We understand our responsibilities to protect personal information and have taken the necessary measures to gain the trust of our customers.

      <br>
      <br>
      We acquire, manage, and use personal information in an appropriate manner, taking into account the nature and scale of our business. We only handle personal information that is necessary to achieve the intended purpose, and we obtain prior consent from the relevant individual.

      <br>
      <br>
      We do not provide personal data to third parties unless we have obtained the consent of the relevant individual, or if there is a legal requirement to do so.
      <br>
      <br>

      We comply with all laws, regulations, national guidelines, and other norms related to the handling of personal information.
      <br>
      <br>

      We take the necessary measures and corrective actions to prevent the leakage, loss, or damage of personal information.
      <br>
      <br>

      We have established a complaint and consultation center regarding the handling of personal information and the personal information protection management system, and respond accordingly.
      <br>
      <br>

      We operate our personal information protection management system based on the Japanese Industrial Standards (JISQ15001) and continually review and improve it.

      <br>
      <br>
      Date of enactment: June 2, 2011
      <br>
      <br>
      Last revised date: January 31, 2023
      <br>
      <br>
      MATSUMOTO INC.
      <br>
      <br>
      Daiki Matsumoto, President and Chief Executive Officer
      <br>
      <br>

      Handling of Personal Information
      <br>
      <br>

      MATSUMOTO INC.
      <br>
      <br>
      Kazutoshi Tokunaga, Personal Information Protection Administrator
      <br>
      <br>

      We may handle your personal information in connection with transactions. We recognize the importance of protecting personal information and handle it appropriately in accordance with our Personal Information Protection Policy.
      <br>
      <br>

      Our basic stance on the handling of personal information is as follows:
      <br>
      <br>
      <br>

      We declare our Personal Information Protection Policy and ensure that our employees and related parties are fully aware of it.
      <br>
      <br>
      We acquire personal information by lawful and fair means and will not acquire it by illegal means.
      <br>
      <br>
      We inform individuals of the voluntary nature of providing personal information and the consequences of not doing so.
      <br>
      <br>
      We handle personal information for the following purposes:

      <br>
      <br>
      <br>
      To print or send graduation albums, commemorative publications, direct mail, or other materials, or to provide services on our NFT marketplace or other services.
      <br>
      <br>
      To respond to inquiries about our operations.
      <br>
      <br>
      To provide guidance and information regarding our operations from us.
      <br>
      <br>
      To provide services to our customers.
      <br>
      <br>
      To advertise, promote or market our company.
      <br>
      <br>
      To take anti-fraud measures necessary for the safe operation of our website, etc.
      <br>
      <br>
      To provide information to a third party in the manner set forth in the Personal Information Protection Policy.
      <br>
      <br>
      If we use personal information for purposes other than those listed above, we will ask for consent unless otherwise permitted by law.

      <br>
      <br>
      Non-disclosure/Non-provision of Personal Data to Third Parties

      We do not disclose or provide your personal data to any third party without obtaining prior consent from you, except in the following cases:

      <br>
      <br>
      If it is necessary based on laws and regulations.
      <br>
      <br>
      If it is necessary for the protection of life, body, or property of any individual and it is difficult to obtain your consent.
      <br>
      <br>
      If it is particularly necessary for the improvement of public health or the promotion of the sound upbringing of children and it is difficult to obtain your consent.
      <br>
      <br>
      If it is necessary to cooperate with any governmental institution or local government or a person entrusted by them in the performance of affairs prescribed by the laws and regulations, and obtaining your consent may interfere with the performance of such affairs.
      <br>
      <br>

      Procedures for changing the Personal Information Protection Policy
      <br>
      <br>
      In order to handle personal information appropriately, we review and revise our Personal Information from time to time.
      <br>
      <br>

      <h2>Contact center for disclosure, correction/suspension of use and complaints</h2>
      With respect to the personal data held by us under the Act on the Protection of Personal Information, youmay request the notification, disclosure, suspension of use, elimination and suspension of provision to athird party of the purpose of use, disclosure, correction, addition, deletion, suspension of use andelimination of the Third Party Provision Records, as well as correction, addition, and deletion of theretained personal data (the Request for Disclosures). When making the Request for Disclosures, wemay ask you to present your identification documents (including driver's license and passport) to confirmyour identity. However, if your request does not meet the requirements set forth in the Act on theProtection of Personal Information or if there are any grounds to refuse disclosures under the Act on theProtection of Personal Information or other laws and regulations, we may not accept your request. Inaddition, it should be noted that we basically refuse to disclose information related to personnelevaluations regardless of whether such information falls under the category of personal information. Formore information about the procedures for the Request for Disclosures, please contact the Contac tInformation for Personal Information Protection below. When we receive a request for notification of the purpose of use or a request for disclosure of the retained personal data held by our company, or a requestfor disclosure of the Third Party Provision Records, we will charge a fee of 1,000 yen per case.
      In addition, you can make inquiries about our handling of personal information and personal informationprotection management at the following customer support contact center.
      Upon receiving an inquiry from you, the contact center will inform you of the procedures for the Requestfor Disclosures, and will respond to it appropriately and promptly to the reasonable extent.
      Note
      <h2>Contact Information for Personal Information Protection</h2>
      <ul>
        <li>
          1 -2 -1 Yashinoki, Moji-ku, Kitakyushu, Fukuoka 800-8555
        </li>
        <li>
          Personal Information Protection External Contact Center, MATSUMOTO INC.Kazutoshi Tokunaga, Personal Information Protection Administrator
        </li>
      </ul>
      <br>
      TEL: 093-371-0298
      <br>
      Name of authorized personal information protection organization:
      <br>
      JIPDEC
      <br>
      Contact for resolving complaints:
      <br>
      Personal Information Protection Consultation Service Office
      <br>
      Address:
      <br>
      Roppongi First Building, 1-9-9 Roppongi, Minato-ku, Tokyo, 106-0032
      <br>
      Phone:
      <br>
      03-5860-7565, 0120-700-779
      <br>
      <br>
      <a name="4" />*[This is not a contact information for the products and services of MATSUMOTO INC.]
      END
      Date of enactment: June 2, 2011Last revised date: January 31, 2023
      <br>
    </div>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Privacy",
    "components": {
    },
    "props": {
    },
    setup () {

        const icon = iconStore(),
            serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                console.log("translation.getLang()");
                console.log(translation.getLang());

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            "icon": icon.get,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  line-height: 50px;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>
