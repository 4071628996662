
export const shinoViNFTFactoryABI = [
        {"inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"},
        {"inputs": [
            {"internalType": "string",
                "name": "_name",
                "type": "string"},
            {"internalType": "string",
                "name": "_symbol",
                "type": "string"},
            {"internalType": "string",
                "name": "_metadata",
                "type": "string"},
            {"internalType": "address",
                "name": "_owner",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_royaltyFee",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_royaltyRecipient",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_platformFeeA",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_platformRecipientA",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_platformFeeB",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_platformRecipientB",
                "type": "address"}
        ],
        "name": "create",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"}
    ],
    shinoViMultiNFTFactoryABI = [],
    shinoViNFTABI = [
        {"inputs": [
            {"internalType": "string",
                "name": "_name",
                "type": "string"},
            {"internalType": "string",
                "name": "_symbol",
                "type": "string"},
            {"internalType": "string",
                "name": "_contractMetadata",
                "type": "string"},
            {"internalType": "address",
                "name": "_owner",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_royaltyFee",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_royaltyRecipient",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_platformFeeA",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_platformRecipientA",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_platformFeeB",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_platformRecipientB",
                "type": "address"}
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "approved",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"}
            ],
            "name": "Approval",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "operator",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "bool",
                    "name": "approved",
                    "type": "bool"}
            ],
            "name": "ApprovalForAll",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "previousOwner",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"}
            ],
            "name": "OwnershipTransferred",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "from",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "to",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"}
            ],
            "name": "Transfer",
            "type": "event"},
        {"inputs": [
            {"internalType": "address",
                "name": "to",
                "type": "address"},
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "owner",
                "type": "address"}
        ],
        "name": "balanceOf",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "to",
                "type": "address"},
            {"internalType": "string[]",
                "name": "uri",
                "type": "string[]"}
        ],
        "name": "batchMint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [],
            "name": "contractURI",
            "outputs": [
                {"internalType": "string",
                    "name": "",
                    "type": "string"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "getApproved",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [],
            "name": "getRoyaltyFee",
            "outputs": [
                {"internalType": "uint256",
                    "name": "",
                    "type": "uint256"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [],
            "name": "getRoyaltyRecipient",
            "outputs": [
                {"internalType": "address",
                    "name": "",
                    "type": "address"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "owner",
                "type": "address"},
            {"internalType": "address",
                "name": "operator",
                "type": "address"}
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "isMinter",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "to",
                "type": "address"},
            {"components": [
                {"internalType": "uint256",
                    "name": "id",
                    "type": "uint256"},
                {"internalType": "uint256",
                    "name": "price",
                    "type": "uint256"},
                {"internalType": "string",
                    "name": "uri",
                    "type": "string"},
                {"internalType": "bytes",
                    "name": "signature",
                    "type": "bytes"}
            ],
            "internalType": "struct ShinoViNFT.Voucher",
            "name": "voucher",
            "type": "tuple"}
        ],
        "name": "lazyMint",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"}
        ],
        "stateMutability": "payable",
        "type": "function"},
        {"inputs": [],
            "name": "name",
            "outputs": [
                {"internalType": "string",
                    "name": "",
                    "type": "string"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [],
            "name": "owner",
            "outputs": [
                {"internalType": "address",
                    "name": "",
                    "type": "address"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "ownerOf",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"},
            {"internalType": "uint256",
                "name": "_salePrice",
                "type": "uint256"}
        ],
        "name": "royaltyInfo",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "from",
                "type": "address"},
            {"internalType": "address",
                "name": "to",
                "type": "address"},
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "from",
                "type": "address"},
            {"internalType": "address",
                "name": "to",
                "type": "address"},
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"},
            {"internalType": "bytes",
                "name": "data",
                "type": "bytes"}
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "operator",
                "type": "address"},
            {"internalType": "bool",
                "name": "approved",
                "type": "bool"}
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "string",
                "name": "_metadata",
                "type": "string"}
        ],
        "name": "setContractURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "account",
                "type": "address"},
            {"internalType": "bool",
                "name": "value",
                "type": "bool"}
        ],
        "name": "setWhitelist",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"}
        ],
        "name": "supportsInterface",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [],
            "name": "symbol",
            "outputs": [
                {"internalType": "string",
                    "name": "",
                    "type": "string"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "tokenURI",
        "outputs": [
            {"internalType": "string",
                "name": "",
                "type": "string"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "from",
                "type": "address"},
            {"internalType": "address",
                "name": "to",
                "type": "address"},
            {"internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"}
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "newOwner",
                "type": "address"}
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "_royaltyFee",
                "type": "uint256"}
        ],
        "name": "updateRoyaltyFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"}
        ],
        "name": "vouchers",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [],
            "name": "withdraw",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"}
    ],
    shinoViMarketplaceABI = [
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "offerPrice",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "offerer",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "nftOwner",
                    "type": "address"}
            ],
            "name": "AcceptedOffer",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "creator",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "winner",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "price",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "caller",
                    "type": "address"}
            ],
            "name": "AuctionResult",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "offerPrice",
                    "type": "uint256"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "offerer",
                    "type": "address"}
            ],
            "name": "CanceledOffer",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "price",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "minBid",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "startTime",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "endTime",
                    "type": "uint256"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "creator",
                    "type": "address"}
            ],
            "name": "CreatedAuction",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": false,
                    "internalType": "uint8",
                    "name": "version",
                    "type": "uint8"}
            ],
            "name": "Initialized",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "price",
                    "type": "uint256"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"}
            ],
            "name": "ListedNFT",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "offerPrice",
                    "type": "uint256"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "offerer",
                    "type": "address"}
            ],
            "name": "OfferredNFT",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "bidPrice",
                    "type": "uint256"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "bidder",
                    "type": "address"}
            ],
            "name": "PlacedBid",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "price",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "buyer",
                    "type": "address"}
            ],
            "name": "SoldNFT",
            "type": "event"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_offerer",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_index",
                "type": "uint256"}
        ],
        "name": "acceptOffer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"},
            {"internalType": "uint256",
                "name": "_index",
                "type": "uint256"}
        ],
        "name": "cancelOffer",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"},
            {"internalType": "uint256",
                "name": "_amount",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_payableToken",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_offerPrice",
                "type": "uint256"}
        ],
        "name": "createOffer",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"}
        ],
        "name": "deListing",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"}
        ],
        "name": "getListedNFT",
        "outputs": [
            {"components": [
                {"internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "owner",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "price",
                    "type": "uint256"},
                {"internalType": "uint256",
                    "name": "chainId",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"internalType": "bool",
                    "name": "sold",
                    "type": "bool"}
            ],
            "internalType": "struct Listing",
            "name": "",
            "type": "tuple"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"}
        ],
        "name": "getOfferredNFT",
        "outputs": [
            {"components": [
                {"internalType": "bool",
                    "name": "accepted",
                    "type": "bool"},
                {"internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"internalType": "uint256",
                    "name": "offerPrice",
                    "type": "uint256"},
                {"internalType": "address payable",
                    "name": "offerer",
                    "type": "address"},
                {"internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"internalType": "address",
                    "name": "payableToken",
                    "type": "address"}
            ],
            "internalType": "struct Offer[]",
            "name": "",
            "type": "tuple[]"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_shinoViPlatform",
                "type": "address"}
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"},
            {"internalType": "uint256",
                "name": "_amount",
                "type": "uint256"},
            {"internalType": "uint256",
                "name": "_price",
                "type": "uint256"},
            {"internalType": "uint256",
                "name": "_chainId",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_payableToken",
                "type": "address"}
        ],
        "name": "listNFT",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_payableToken",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_price",
                "type": "uint256"}
        ],
        "name": "purchaseNFT",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_token",
                "type": "address"},
            {"internalType": "bool",
                "name": "_enable",
                "type": "bool"}
        ],
        "name": "setPayableToken",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "setShinoViNFTPlatform",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"}
    ],
    shinoViPlatformABI = [
        {"anonymous": false,
            "inputs": [
                {"indexed": false,
                    "internalType": "uint8",
                    "name": "version",
                    "type": "uint8"}
            ],
            "name": "Initialized",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "string",
                    "name": "name",
                    "type": "string"},
                {"indexed": false,
                    "internalType": "string",
                    "name": "symbol",
                    "type": "string"},
                {"indexed": true,
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"},
                {"indexed": false,
                    "internalType": "uint256",
                    "name": "royaltyFee",
                    "type": "uint256"},
                {"indexed": false,
                    "internalType": "address",
                    "name": "royaltyRecipient",
                    "type": "address"}
            ],
            "name": "createNFTEvent",
            "type": "event"},
        {"inputs": [
            {"internalType": "address",
                "name": "_user",
                "type": "address"},
            {"internalType": "address[]",
                "name": "_nfts",
                "type": "address[]"}
        ],
        "name": "addNFT",
        "outputs": [
            {"internalType": "address[]",
                "name": "",
                "type": "address[]"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "addShinoViAdmin",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "addShinoViCreator",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "string",
                "name": "_name",
                "type": "string"},
            {"internalType": "string",
                "name": "_symbol",
                "type": "string"},
            {"internalType": "string",
                "name": "_metadata",
                "type": "string"},
            {"internalType": "address",
                "name": "_owner",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_royaltyFee",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_royaltyRecipient",
                "type": "address"}
        ],
        "name": "createERC1155",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "string",
                "name": "_name",
                "type": "string"},
            {"internalType": "string",
                "name": "_symbol",
                "type": "string"},
            {"internalType": "string",
                "name": "_metadata",
                "type": "string"},
            {"internalType": "address",
                "name": "_owner",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_royaltyFee",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_royaltyRecipient",
                "type": "address"}
        ],
        "name": "createERC721",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [],
            "name": "fetchMyNFTs",
            "outputs": [
                {"internalType": "address[]",
                    "name": "",
                    "type": "address[]"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "getCustomFees",
        "outputs": [
            {"components": [
                {"internalType": "address",
                    "name": "recipientA",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeA",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "recipientB",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeB",
                    "type": "uint256"}
            ],
            "internalType": "struct ShinoViPlatform.PlatformFee",
            "name": "",
            "type": "tuple"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [],
            "name": "getDefaultFees",
            "outputs": [
                {"components": [
                    {"internalType": "address",
                        "name": "recipientA",
                        "type": "address"},
                    {"internalType": "uint256",
                        "name": "feeA",
                        "type": "uint256"},
                    {"internalType": "address",
                        "name": "recipientB",
                        "type": "address"},
                    {"internalType": "uint256",
                        "name": "feeB",
                        "type": "uint256"}
                ],
                "internalType": "struct ShinoViPlatform.PlatformFee",
                "name": "",
                "type": "tuple"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [],
            "name": "getLazyMintFees",
            "outputs": [
                {"components": [
                    {"internalType": "address",
                        "name": "recipientA",
                        "type": "address"},
                    {"internalType": "uint256",
                        "name": "feeA",
                        "type": "uint256"},
                    {"internalType": "address",
                        "name": "recipientB",
                        "type": "address"},
                    {"internalType": "uint256",
                        "name": "feeB",
                        "type": "uint256"}
                ],
                "internalType": "struct ShinoViPlatform.PlatformFee",
                "name": "",
                "type": "tuple"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_seller",
                "type": "address"}
        ],
        "name": "getPlatformFees",
        "outputs": [
            {"components": [
                {"internalType": "address",
                    "name": "recipientA",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeA",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "recipientB",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeB",
                    "type": "uint256"}
            ],
            "internalType": "struct ShinoViPlatform.PlatformFee",
            "name": "",
            "type": "tuple"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"}
        ],
        "name": "getRoyaltyFee",
        "outputs": [
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"}
        ],
        "name": "getRoyaltyRecipient",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [],
            "name": "getShinoViMultiNFTFactory",
            "outputs": [
                {"internalType": "address",
                    "name": "",
                    "type": "address"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [],
            "name": "getShinoViNFTFactory",
            "outputs": [
                {"internalType": "address",
                    "name": "",
                    "type": "address"}
            ],
            "stateMutability": "view",
            "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nftFactory",
                "type": "address"},
            {"internalType": "address",
                "name": "_multiNFTFactory",
                "type": "address"},
            {"internalType": "address",
                "name": "_platformRecipientA",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_feeA",
                "type": "uint256"},
            {"internalType": "address",
                "name": "_platformRecipientB",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_feeB",
                "type": "uint256"}
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "isShinoViAdmin",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "isShinoViCreator",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "isShinoViNFT",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"components": [
                {"internalType": "address",
                    "name": "nft",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"},
                {"internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"},
                {"internalType": "uint256",
                    "name": "price",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "payableToken",
                    "type": "address"},
                {"internalType": "address",
                    "name": "seller",
                    "type": "address"},
                {"internalType": "address",
                    "name": "buyer",
                    "type": "address"},
                {"internalType": "bool",
                    "name": "transferFrom",
                    "type": "bool"}
            ],
            "internalType": "struct ShinoViPlatform.Transaction",
            "name": "t",
            "type": "tuple"}
        ],
        "name": "processTransaction",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "removeNFT",
        "outputs": [
            {"internalType": "address[]",
                "name": "",
                "type": "address[]"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "removeShinoViAdmin",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "removeShinoViCreator",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_nft",
                "type": "address"},
            {"internalType": "address",
                "name": "_from",
                "type": "address"},
            {"internalType": "address",
                "name": "_to",
                "type": "address"},
            {"internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"},
            {"internalType": "uint256",
                "name": "_amount",
                "type": "uint256"}
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"},
            {"components": [
                {"internalType": "address",
                    "name": "recipientA",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeA",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "recipientB",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeB",
                    "type": "uint256"}
            ],
            "internalType": "struct ShinoViPlatform.PlatformFee",
            "name": "_platformFee",
            "type": "tuple"}
        ],
        "name": "setCustomFees",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"components": [
                {"internalType": "address",
                    "name": "recipientA",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeA",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "recipientB",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeB",
                    "type": "uint256"}
            ],
            "internalType": "struct ShinoViPlatform.PlatformFee",
            "name": "_platformFee",
            "type": "tuple"}
        ],
        "name": "setDefaultFees",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"components": [
                {"internalType": "address",
                    "name": "recipientA",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeA",
                    "type": "uint256"},
                {"internalType": "address",
                    "name": "recipientB",
                    "type": "address"},
                {"internalType": "uint256",
                    "name": "feeB",
                    "type": "uint256"}
            ],
            "internalType": "struct ShinoViPlatform.PlatformFee",
            "name": "_platformFee",
            "type": "tuple"}
        ],
        "name": "setLazyMintFees",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "setShinoViMultiNFTFactory",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "_address",
                "type": "address"}
        ],
        "name": "setShinoViNFTFactory",
        "outputs": [
            {"internalType": "bool",
                "name": "",
                "type": "bool"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"}
    ],
    ERC20ABI = [
        {"constant": true,
            "inputs": [],
            "name": "name",
            "outputs": [
                {"name": "",
                    "type": "string"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": false,
            "inputs": [
                {"name": "spender",
                    "type": "address"},
                {"name": "tokens",
                    "type": "uint256"}
            ],
            "name": "approve",
            "outputs": [
                {"name": "success",
                    "type": "bool"}
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"constant": true,
            "inputs": [],
            "name": "totalSupply",
            "outputs": [
                {"name": "",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": false,
            "inputs": [
                {"name": "from",
                    "type": "address"},
                {"name": "to",
                    "type": "address"},
                {"name": "tokens",
                    "type": "uint256"}
            ],
            "name": "transferFrom",
            "outputs": [
                {"name": "success",
                    "type": "bool"}
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"constant": true,
            "inputs": [],
            "name": "decimals",
            "outputs": [
                {"name": "",
                    "type": "uint8"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": false,
            "inputs": [
                {"name": "amount",
                    "type": "uint256"}
            ],
            "name": "withdrawEther",
            "outputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"constant": true,
            "inputs": [],
            "name": "_totalSupply",
            "outputs": [
                {"name": "",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": true,
            "inputs": [
                {"name": "tokenOwner",
                    "type": "address"}
            ],
            "name": "balanceOf",
            "outputs": [
                {"name": "balance",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": false,
            "inputs": [],
            "name": "acceptOwnership",
            "outputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"constant": true,
            "inputs": [],
            "name": "owner",
            "outputs": [
                {"name": "",
                    "type": "address"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": true,
            "inputs": [],
            "name": "symbol",
            "outputs": [
                {"name": "",
                    "type": "string"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": true,
            "inputs": [
                {"name": "a",
                    "type": "uint256"},
                {"name": "b",
                    "type": "uint256"}
            ],
            "name": "safeSub",
            "outputs": [
                {"name": "c",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "pure",
            "type": "function"},
        {"constant": false,
            "inputs": [
                {"name": "to",
                    "type": "address"},
                {"name": "tokens",
                    "type": "uint256"}
            ],
            "name": "transfer",
            "outputs": [
                {"name": "success",
                    "type": "bool"}
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"constant": true,
            "inputs": [
                {"name": "a",
                    "type": "uint256"},
                {"name": "b",
                    "type": "uint256"}
            ],
            "name": "safeDiv",
            "outputs": [
                {"name": "c",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "pure",
            "type": "function"},
        {"constant": false,
            "inputs": [
                {"name": "spender",
                    "type": "address"},
                {"name": "tokens",
                    "type": "uint256"},
                {"name": "data",
                    "type": "bytes"}
            ],
            "name": "approveAndCall",
            "outputs": [
                {"name": "success",
                    "type": "bool"}
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"constant": true,
            "inputs": [
                {"name": "a",
                    "type": "uint256"},
                {"name": "b",
                    "type": "uint256"}
            ],
            "name": "safeMul",
            "outputs": [
                {"name": "c",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "pure",
            "type": "function"},
        {"constant": true,
            "inputs": [],
            "name": "newOwner",
            "outputs": [
                {"name": "",
                    "type": "address"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": false,
            "inputs": [
                {"name": "tokenAddress",
                    "type": "address"},
                {"name": "tokens",
                    "type": "uint256"}
            ],
            "name": "transferAnyERC20Token",
            "outputs": [
                {"name": "success",
                    "type": "bool"}
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"constant": true,
            "inputs": [
                {"name": "tokenOwner",
                    "type": "address"},
                {"name": "spender",
                    "type": "address"}
            ],
            "name": "allowance",
            "outputs": [
                {"name": "remaining",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "view",
            "type": "function"},
        {"constant": true,
            "inputs": [
                {"name": "a",
                    "type": "uint256"},
                {"name": "b",
                    "type": "uint256"}
            ],
            "name": "safeAdd",
            "outputs": [
                {"name": "c",
                    "type": "uint256"}
            ],
            "payable": false,
            "stateMutability": "pure",
            "type": "function"},
        {"constant": false,
            "inputs": [
                {"name": "_newOwner",
                    "type": "address"}
            ],
            "name": "transferOwnership",
            "outputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"},
        {"inputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "constructor"},
        {"payable": true,
            "stateMutability": "payable",
            "type": "fallback"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "name": "_from",
                    "type": "address"},
                {"indexed": true,
                    "name": "_to",
                    "type": "address"}
            ],
            "name": "OwnershipTransferred",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "name": "from",
                    "type": "address"},
                {"indexed": true,
                    "name": "to",
                    "type": "address"},
                {"indexed": false,
                    "name": "tokens",
                    "type": "uint256"}
            ],
            "name": "Transfer",
            "type": "event"},
        {"anonymous": false,
            "inputs": [
                {"indexed": true,
                    "name": "tokenOwner",
                    "type": "address"},
                {"indexed": true,
                    "name": "spender",
                    "type": "address"},
                {"indexed": false,
                    "name": "tokens",
                    "type": "uint256"}
            ],
            "name": "Approval",
            "type": "event"}
    ],
    ERC20FactoryABI = [
        {"inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"},
        {"inputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"},
            {"internalType": "uint256",
                "name": "",
                "type": "uint256"}
        ],
        "name": "contracts",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "string",
                "name": "name",
                "type": "string"},
            {"internalType": "string",
                "name": "symbol",
                "type": "string"},
            {"internalType": "uint256",
                "name": "initialSupply",
                "type": "uint256"}
        ],
        "name": "create",
        "outputs": [
            {"internalType": "contract baseToken",
                "name": "creditsAddress",
                "type": "address"}
        ],
        "stateMutability": "nonpayable",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "name": "owners",
        "outputs": [
            {"internalType": "address",
                "name": "",
                "type": "address"}
        ],
        "stateMutability": "view",
        "type": "function"},
        {"inputs": [
            {"internalType": "address",
                "name": "to",
                "type": "address"},
            {"internalType": "address payable",
                "name": "token",
                "type": "address"},
            {"internalType": "uint256",
                "name": "amount",
                "type": "uint256"}
        ],
        "name": "sendTokens",
        "outputs": [
            {"internalType": "uint256",
                "name": "balanceRemain",
                "type": "uint256"}
        ],
        "stateMutability": "payable",
        "type": "function"}
    ];

export default {shinoViPlatformABI,
    shinoViMarketplaceABI,
    shinoViNFTFactoryABI,
    shinoViMultiNFTFactoryABI,
    shinoViNFTABI,
    ERC20ABI,
    ERC20FactoryABI};

